import {useState, useMemo, useEffect} from "react";
import { useParams, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { app } from "../firebaseConfig";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
const ModelViewer = () => {
  let query = useQuery();
  const id = query.get("model_id");
  const pw = query.get("password");
  let { order_id } = useParams();
  const [password, setPassword] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (id && pw) {
      verifyPw(pw).then()
    }
  }, [id, pw]);

  const handleClose = () => {
    setOpen(false);
  };

  const verifyPw = async (pw?: string) => {
    let _password = pw || password
    const db = getFirestore(app);

    const ordersCol = collection(db, `${order_id}`);

    const querySnapshot = await getDocs(ordersCol);
    querySnapshot.forEach((doc) => {
      if (doc.data().password === _password) {
        setIsVerified(true);
        handleClose();
      } else {
        console.error("password wrong");
      }
    });
  }

  const handleSubmit = async () => {
    await verifyPw()
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Überprüfung des Passworts</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bitte geben Sie das Passwort ein, das Sie bei Ihrer Bestellung
            erhalten haben, bevor Sie den Inhalt betrachten
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Passwort"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleSubmit}>Bestätigen</Button>
        </DialogActions>
      </Dialog>
      {isVerified && (
        <div style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
          <iframe
            width="100%"
            height="100%"
            title="Apple_vision_pro_2023"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            src={`https://sketchfab.com/models/${id}/embed?ui_infos=0&ui_watermark_link=0&ui_watermark=0`}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default ModelViewer;
