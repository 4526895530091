// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBUzTogOtbIp1vtVdhTTpUvY9KB_6BG0U4",
  authDomain: "scantastic-store.firebaseapp.com",
  projectId: "scantastic-store",
  storageBucket: "scantastic-store.appspot.com",
  messagingSenderId: "218548386870",
  appId: "1:218548386870:web:c7ad924831605f704b6c97",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export { app };
